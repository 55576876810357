import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {UiModule} from '../ui/ui.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MainLayoutComponent
  ],
  imports     : [
    CommonModule,
    UiModule
  ],
  exports     : [
    HeaderComponent,
    FooterComponent
  ]
})
export class CoreModule {
}
