<!-- HEADER START -->
<header class="site-header header-style-1">
  <div class="sticky-header main-bar-wrapper">
    <div class="main-bar bg-white p-t5">
      <div class="container">
        <div class="logo-header">
          <div class="logo-header-inner logo-header-one">
            <a href="../../../index.html">
              <img alt="" width="85" height="85" src="../../../assets/images/logo-color-no-background.svg"/>
            </a>
          </div>
        </div>
        <!-- NAV Toggle Button -->
        <button class="navbar-toggle collapsed" data-target=".header-nav" data-toggle="collapse" type="button">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- EXTRA Nav -->
        <div class="extra-nav">
          <div class="extra-cell">
            <a class="text-white clickable" (click)="onClickLang('en')">
              EN
            </a>
          </div>
          <div class="extra-cell">
            <a class="text-white clickable" (click)="onClickLang('ja')">
              JA
            </a>
          </div>
        </div>
        <!-- EXTRA Nav -->

        <!-- MAIN Vav -->
        <div class="header-nav navbar-collapse collapse">
          <ul class=" nav navbar-nav">
<!--            <li class="active">-->
<!--              <a href="javascript:">Home</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="javascript:">Post detail</a>-->
<!--              <ul class="sub-menu">-->
<!--                <li><a href="post-image.html">Post Image</a></li>-->
<!--                <li><a href="post-gallery.html">Post Gallery</a></li>-->
<!--                <li><a href="post-video.html">Post Video</a></li>-->
<!--                <li><a href="post-right-sidebar.html">Post Right Sidebar</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li class="submenu-direction">-->
<!--              <a href="javascript:">Pages</a>-->
<!--              <ul class="sub-menu">-->
<!--                <li>-->
<!--                  <a href="javascript:">Blog</a>-->
<!--                  <ul class="sub-menu has-child">-->
<!--                    <li><a href="news-grid.html">Blog Grid</a></li>-->
<!--                    <li><a href="news-listing.html">Blog Listing</a></li>-->
<!--                    <li><a href="news-masonry.html">Blog Masonry</a></li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="about-1.html">About us</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="contact-1.html">Contact us</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="javascript:">Projects</a>-->
<!--              <ul class="sub-menu">-->
<!--                <li><a href="work-grid.html">Project Grid</a></li>-->
<!--                <li><a href="work-masonry.html">Project Masonry</a></li>-->
<!--                <li><a href="work-carousel.html">Project Carousel</a></li>-->
<!--                <li><a href="project-detail.html">Project Detail</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li class="submenu-direction">-->
<!--              <a href="javascript:">Shortcodes</a>-->
<!--              <ul class="sub-menu">-->
<!--                <li><a href="accordion.html">Accordion</a></li>-->
<!--                <li><a href="button.html">Button</a></li>-->
<!--                <li><a href="icon_box.html">Icon box style</a></li>-->
<!--                <li><a href="list_group.html">List group</a></li>-->
<!--                <li><a href="modal_popup.html">Modal popup</a></li>-->
<!--                <li><a href="tabs.html">Tabs</a></li>-->
<!--                <li><a href="table.html">Table</a></li>-->
<!--                <li><a href="video.html">Video </a></li>-->
<!--                <li><a href="icon-font.html">Icon Font </a></li>-->
<!--              </ul>-->
<!--            </li>-->
          </ul>
        </div>

      </div>
    </div>
  </div>
</header>
<!-- HEADER END -->
