import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomePageComponent} from './home-page.component';
import {RouterModule} from '@angular/router';
import {UiModule} from '../ui/ui.module';

export const homeRoutes = [
  {
    path     : '',
    component: HomePageComponent,
  },
];

@NgModule({
  declarations: [
    HomePageComponent
  ],
  imports     : [
    UiModule,
    RouterModule.forChild(homeRoutes),
    CommonModule,
  ]
})
export class HomePageModule {
}
