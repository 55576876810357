<!-- SECTION CONTENTG START -->
<div class="section-full p-tb80">
  <!-- LOCATION BLOCK-->
  <div class="container">

    <!-- CONTACT FORM -->
    <div class="section-content">
      <!-- CONTACT FORM-->
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="contact-info text-black m-b30">
            <!-- TITLE START -->
            <div class="section-head text-left">
              <h2 class="m-b5">Contact Info</h2>
            </div>
            <!-- TITLE END -->
            <div class="wt-icon-box-wrapper left p-b40">
              <div class="icon-xs"><i class="fa fa-phone"></i></div>
              <div class="icon-content">
                <h5 class="m-t0 font-weight-500">Phone number</h5>
                <p>(456) 789 10 12</p>
              </div>
            </div>

            <div class="wt-icon-box-wrapper left p-b40">
              <div class="icon-xs"><i class="fa fa-envelope"></i></div>
              <div class="icon-content">
                <h5 class="m-t0 font-weight-500">Email address</h5>
                <p>demo@gmail.com</p>
              </div>
            </div>

            <div class="wt-icon-box-wrapper left">
              <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
              <div class="icon-content">
                <h5 class="m-t0 font-weight-500">Address info</h5>
                <p>55/11 Land Street, Modern New Yourk City, USA</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-6">
          <form action="form-handler.php" class="contact-form cons-contact-form" method="post">
            <div class="contact-one m-b30">

              <!-- TITLE START -->
              <div class="section-head text-left">
                <h2 class="m-b5">Get In Touch</h2>
              </div>
              <!-- TITLE END -->

              <div class="form-group">
                <input class="form-control" name="username" placeholder="Name" required
                       type="text">
              </div>

              <div class="form-group">
                <input class="form-control" name="email" placeholder="Email" required
                       type="text">
              </div>

              <div class="form-group">
                <textarea class="form-control " name="message" placeholder="Message"
                          required
                          rows="4"></textarea>
              </div>

              <div class="text-right">
                <button class="btn-half site-button button-lg m-b15" name="submit" type="submit"
                        value="Submit">
                  <span>Submit</span><em></em>
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- SECTION CONTENT END -->
