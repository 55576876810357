<!-- SECTION CONTENTG START -->
<div class="section-full p-tb80">
  <!-- LOCATION BLOCK-->
  <div class="container">

    <div class="gmap-outline">
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map>
    </div>
  </div>
</div>

<!-- SECTION CONTENT END -->
