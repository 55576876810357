<!-- OUR TEAM START -->
<div class="section-full p-t80 p-b80 bg-white">
  <div class="container">

    <!-- TITLE START -->
    <div class="section-head text-left">
      <h2 class="  m-b5" data-title="Team">Our Team</h2>
      <div class="wt-separator-outer">
        <div class="wt-separator bg-primary"></div>
      </div>
    </div>
    <!-- TITLE END -->

    <!-- IMAGE CAROUSEL START -->
    <div class="our-team-two p-lr15">
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="wt-team-arc2">

            <div class="wt-media">
              <img alt="" src="assets/images/our-team1/pic1.jpg">
              <div class="team-social-center">
                <ul class="team-social-icon">
                  <li><a class="fa fa-facebook" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-twitter" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-instagram" href="javascript:void(0);"></a></li>
                </ul>
              </div>
            </div>

            <div class="wt-info">
              <div class="team-detail  text-center">
                <h4 class="m-t0">Mizuki Maezono</h4>
                <p>CEO</p>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="wt-team-arc2">

            <div class="wt-media">
              <img alt="" src="assets/images/our-team1/pic2.jpg">
              <div class="team-social-center">
                <ul class="team-social-icon">
                  <li><a class="fa fa-facebook" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-twitter" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-instagram" href="javascript:void(0);"></a></li>
                </ul>
              </div>
            </div>

            <div class="wt-info">
              <div class="team-detail  text-center">
                <h4 class="m-t0">Kisho Tamura</h4>
                <p>Chef</p>
              </div>
            </div>

          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <div class="wt-team-arc2">

            <div class="wt-media">
              <img alt="" src="assets/images/our-team1/pic3.jpg">
              <div class="team-social-center">
                <ul class="team-social-icon">
                  <li><a class="fa fa-facebook" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-twitter" href="javascript:void(0);"></a></li>
                  <li><a class="fa fa-instagram" href="javascript:void(0);"></a></li>
                </ul>
              </div>
            </div>

            <div class="wt-info">
              <div class="team-detail text-center">
                <h4 class="m-t0">Tsubasa Hikichi</h4>
                <p>CTO</p>
              </div>
            </div>

          </div>
        </div>

      </div>


    </div>
  </div>

</div>
<!-- OUR TEAM END -->
