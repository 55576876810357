import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {rootRoutes} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports     : [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(rootRoutes, {
      enableTracing     : false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation : 'enabled',
      useHash           : false
    }),
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide   : TranslateLoader,
        useFactory: createTranslateLoader,
        deps      : [HttpClient]
      }
    })
  ],
  providers   : [],
  bootstrap   : [AppComponent]
})
export class AppModule {
}
