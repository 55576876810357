import {Component, OnInit} from '@angular/core';

@Component({
  selector   : 'app-map',
  templateUrl: './map.component.html',
  styleUrls  : ['./map.component.scss']
})
export class MapComponent implements OnInit {
  lat = 11.551808;
  lng = 104.927119;
  zoom = 17.5;

  constructor() {
  }

  ngOnInit(): void {
  }

}
