import {Routes} from '@angular/router';
import {MainLayoutComponent} from './core/layouts/main-layout/main-layout.component';
import {HomePageModule} from './home-page/home-page.module';

export const appRoutes: Routes = [
  {
    path        : 'home',
    loadChildren: () => HomePageModule,
  }
];

export const rootRoutes: Routes = [
  {
    path      : '',
    redirectTo: 'home',
    pathMatch : 'full',
  },
  {
    path     : '',
    component: MainLayoutComponent,
    children : appRoutes
  }
];
