import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-screen-slider',
  templateUrl: './full-screen-slider.component.html',
  styleUrls: ['./full-screen-slider.component.scss']
})
export class FullScreenSliderComponent implements OnInit {
  value = 'WORLD';

  constructor() { }

  ngOnInit(): void {
  }

}
