<!-- OUR SPECIALIZATION START -->
<div class="section-full bg-change-section overlay-wrapper p-tb80" data-toggle="tab-hover">
  <div class="overlay-main bg-black opacity-06"></div>
  <div class="bg-changer">
    <div class=" section-bg active" style="background-image:url(assets/images/background/room.jpg)"></div>
    <div class="section-bg" style="background-image:url(assets/images/background/restaurant.jpg)"></div>
    <div class="section-bg" style="background-image:url(assets/images/background/architecture.jpg)"></div>
    <div class="section-bg" style="background-image:url(assets/images/background/interior.jpg)"></div>
  </div>

  <div class="container">
    <!-- TITLE START -->
    <div class="section-head text-left">
      <h2 class="m-b5 text-white" data-title="Specialization" translate>Our Specialization</h2>
      <div class="wt-separator-outer">
        <div class="wt-separator bg-primary"></div>
      </div>
    </div>
    <!-- TITLE END -->
    <div class="row">
      <div class="col-md-6">
        <div class="services-part-left ">
          <div class="text-white">
            <h3 class=" m-t0" translate>Our Specialization content</h3>
            <p translate>Our Specialization detail</p>
          </div>
          <div class="section-content">
            <div class="row">

              <div class="col-md-4 col-sm-4 col-xs-4 col-xs-100pc">
                <div class="m-b30 wt-icon-box-wrapper">
                  <h2 class="text-primary m-b5 font-weight-800"><span class="counter m-r5">406</span><b>+</b></h2>
                  <h5 class="wt-title m-b0 text-white">International Guests</h5>
                </div>
              </div>

              <div class="col-md-4 col-sm-4 col-xs-4 col-xs-100pc">
                <div class="m-b30  wt-icon-box-wrapper">
                  <h2 class="text-primary m-b5 font-weight-800"><span class="counter m-r5">132</span><b>+</b></h2>
                  <h5 class="wt-title m-b0 text-white">Five stars rating</h5>
                </div>
              </div>

              <div class="col-md-4 col-sm-4 col-xs-4 col-xs-100pc">
                <div class="m-b30 wt-icon-box-wrapper">
                  <h2 class="text-primary m-b5 font-weight-800"><span class="counter m-r5">207</span><b>+</b></h2>
                  <h5 class="wt-title m-b0 text-white">Served Breakfast</h5>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row no-col-gap">
          <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc">
            <div class="wt-icon-box-wrapper p-tb20 center bdr-1 bdr-solid bdr-white  bgcall-block hover-box-effect">
              <div class="icon-md text-primary">
                <span class="icon-cell text-white"><i class="flaticon-hotel"></i></span>
              </div>
              <div class="icon-content text-white">
                <h4 class="wt-title m-b10">Rooms</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc">
            <div class="wt-icon-box-wrapper p-tb20 center bdr-1 bdr-solid bdr-white  bgcall-block  hover-box-effect">
              <div class="icon-md text-primary">
                <span class="icon-cell text-white"><i class="flaticon-coffee-cup"></i></span>
              </div>
              <div class="icon-content  text-white">
                <h4 class="wt-title m-b10">Restaurant</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6 col-xs-100pc">
            <div class="wt-icon-box-wrapper p-tb20 center bdr-1 bdr-solid bdr-white  bgcall-block  hover-box-effect">
              <div class="icon-md text-primary">
                <span class="icon-cell text-white"><i class="flaticon-cheers"></i></span>
              </div>
              <div class="icon-content  text-white">
                <h4 class="wt-title m-b10">Luxury Bars</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6  col-sm-6 col-xs-6 col-xs-100pc">
            <div class="wt-icon-box-wrapper p-tb20 center bdr-1 bdr-solid bdr-white bgcall-block hover-box-effect">
              <div class="icon-md text-primary">
                <span class="icon-cell text-white"><i class="flaticon-seats-at-the-hall"></i></span>
              </div>
              <div class="icon-content  text-white">
                <h4 class="wt-title m-b10">Meeting Hall</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OUR SPECIALIZATION END -->
